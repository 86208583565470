@font-face {
	font-family: 'laCartoonerie';
	src: url('/assets/fonts/laCartoonerie/laCartoonerie.eot');
	src: url('/assets/fonts/laCartoonerie/laCartoonerie.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/laCartoonerie/laCartoonerie.woff2') format('woff2'),
		url('/assets/fonts/laCartoonerie/laCartoonerie.woff') format('woff'),
		url('/assets/fonts/laCartoonerie/laCartoonerie.ttf') format('truetype'),
		url('/assets/fonts/laCartoonerie/laCartoonerie.svg#laCartoonerie') format('svg');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}
