.cart.btn {
	i {
		font-size: 1.2rem;
	}

	.label {
		$size: 25px;
		position: absolute;
		top: -9px;
		right: 0px;
		background: var(--secondary);
		color: #fff;
		width: $size;
		height: $size;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
	}
}

/* # $Mobile ::
------------------------------------*/

@media (max-width: 1440px) {
	.cart.btn {
		.label {
			width: 20px;
			height: 20px;
			top: -3px;
    		right: 3px;
			font-size: 12px;
		}
	}
}