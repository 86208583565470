@import 'variables';

.button {
	background-color: #ffffff;
	font-size: 20px;
	color: #707070;
	border-radius: 20px;
	padding: 15px 40px;
}

.button--gray {
	background-color: grey;
	color: #ffffff;
}

.button--default {
	background-color: #ffffff;
	color: #aeaeae;
	border: 1px solid #aeaeae;
}

.button--red {
	background-color: #ff626b;
	color: #ffffff;
	border: 1px solid #ff626b;
}

.button--red--outline {
	background-color: #ffffff;
	color: #ff626b;
	border: 1px solid #ff626b;
}

.button--small {
	font-size: 13px;
	height: 30px;
	min-height: 30px;
	padding: 0 15px;
	border-radius: 15px;
}

.button--extra-small {
	font-size: 10px;
	height: 30px;
	min-height: 30px;
	padding: 0 10px;
	border-radius: 15px;
}

.button--medium {
	font-size: 13px;
	line-height: 20px;
	height: 32px;
	min-height: 32px;
	padding: 0 18px;
	border-radius: 16px;
	min-width: 114px;
	font-style: normal;
}

.button--large {
	font-size: 16px;
	height: 40px;
	min-height: 40px;
	padding: 0 25px;
	border-radius: 20px;
}

.button--hasIcon {
	display: inline-flex;
	align-items: center;

	span {
		margin-left: 12px;
	}
}

.button--circle {
	width: 48px;
	height: 48px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.button-action {
	font-size: 15px;
	line-height: 1.6;
	font-weight: normal;
	border-radius: 4px;
	padding: 4px 16px;
}

@media (max-width: 959px) {
	.vidole-button {
		background-color: $primary;
		outline: none;
		border: none;
		color: $vidole-white;

		&.button-large {
			width: 155px !important;
			height: 50px !important;
			font-size: 14px !important;
		}

		&.button-xl {
			width: 160px !important;
			height: 65px !important;
			font-size: 15px !important;
		}
	}

	.vidole-button-outline {
		&.button-large {
			width: 155px !important;
			height: 50px !important;
			font-size: 14px !important;
		}
	}
}

@media (max-width: 500px) {
	.vidole-button {
		background-color: $primary;
		outline: none;
		border: none;
		color: $vidole-white;

		&.button-large {
			width: 135px !important;
			height: 40px !important;
			font-size: 12px !important;
		}

		&.button-xl {
			width: 125px !important;
			height: 42px !important;
			font-size: 13px !important;
		}
	}

	.vidole-button-outline {
		&.button-large {
			width: 155px !important;
			height: 50px !important;
			font-size: 14px !important;
		}
	}
}

.vidole-button {
	background-color: $primary;
	outline: none;
	border: none;
	color: $vidole-white;
	font-size: 17px;
	text-transform: uppercase;
	box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.2);

	&.button-white {
		background-color: $vidole-white;
		color: $vidole-text-color;
		&.text-orange {
			color: $primary;
		}
		&.text-red {
			color: $vidole-red;
		}
		&:hover {
			background-color: orange;
			transition: background-color 0.5s;
			color: #ffffff;
		}

		&:disabled {
			opacity: 0.7;
			pointer-events: none;
		}
	}

	&.button-large {
		width: 180px;
		height: 60px;
		border-radius: 15px;
	}

	&.button-xl {
		width: 190px;
		height: 70px;
		border-radius: 15px;
		font-size: 20px;
	}

	&.button-md {
		width: 150px;
	}

	&:hover {
		background-color: orange;
		transition: background-color 0.5s;
	}

	&:disabled {
		opacity: 0.6;
	}

	&.bold {
		font-weight: bold;
	}
}

.vidole-button-outline {
	background-color: $vidole-white;
	color: $primary;
	outline: none;
	border: none;
	font-size: 17px;
	text-transform: uppercase;
	box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.2);

	&.button-large {
		width: 180px;
		height: 60px;
		border-radius: 15px;
	}

	&.button-small {
		width: 120px;
		height: 30px;
		border-radius: 20px;
	}

	&:hover {
		background-color: #e6e6e6;
		transition: background-color 0.5s;
	}
}
