.ng-select .ng-select-container .ng-value-container {
	padding-top: 4px;
	padding-bottom: 4px;
	padding-left: 10px;

	.ng-placeholder {
		top: 7px !important;
	}
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
	border-color: $input-focus-border-color;
	outline: 0;
	@if $enable-shadows {
		@include box-shadow($input-box-shadow, $input-focus-box-shadow);
	} @else {
		box-shadow: $input-focus-box-shadow;
	}
}

ng-select.ng-invalid.ng-touched {
	.ng-select-container {
		border-color: #dc3545;
	}
}

ng-dropdown-panel {
	margin-bottom: 5px !important;
}
