@font-face {
	font-family: 'Santalum';
	src: url('/assets/fonts/santalum/Santalum-Regular.eot');
	src: url('/assets/fonts/santalum/Santalum-Regular.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/santalum/Santalum-Regular.woff2') format('woff2'),
		url('/assets/fonts/santalum/Santalum-Regular.woff') format('woff'),
		url('/assets/fonts/santalum/Santalum-Regular.ttf') format('truetype'),
		url('/assets/fonts/santalum/Santalum-Regular.svg#Santalum-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
