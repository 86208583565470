/* You can add global styles to this file, and also import other style files */
@import '../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.carousel.scss';
@import '../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import '../node_modules/@angular/material/prebuilt-themes/indigo-pink.css';
@import '~animate.css/animate.css';
@import '~animate.css/animate.min.css';
@import './theme/scss/theme.scss';
@import '../node_modules/@ng-select/ng-select/themes/default.theme.css';

@font-face {
	font-family: 'Inter';
	src: url('./assets/fonts/Inter-VariableFont_slnt\,wght.ttf') format('truetype');
	font-weight: 500;
}

b,
strong,
.bold {
	font-family: 'Inter', sans-serif !important;
	font-weight: 700;
}

body,
html {
	margin: 0;
	padding: 0;
	scroll-behavior: smooth;
	font-family: Inter;
	background-color: #e9f5f1;
}
* {
	box-sizing: border-box;
	outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: #000000;
}

img {
	width: 100%;
}

div.disabled {
	pointer-events: none;
	opacity: 0.4;
}

.mat-radio-button .mat-radio-inner-circle {
	color: var(--bs-primary);
	background-color: var(--bs-primary) !important;
}

.mat-radio-button .mat-radio-ripple .mat-ripple-element {
	background-color: var(--bs-primary) !important;
}

.vidole-backdrop {
	background-color: rgba($color: #807d7d, $alpha: 0.8);
}

.vidole-backdrop-deep {
	background-color: rgba($color: #807d7d, $alpha: 0.5);
}

.mat-dialog-container {
	border: none !important;
	padding: 0 !important;
}

.custom-dialog-container-legal .mat-dialog-container {
	border: none !important;
	padding: 0 !important;
	overflow-x: hidden;
	overflow-y: auto;

	box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
		0px 24px 38px -38px rgba(0, 0, 0, 0.14), 0px 9px 46px -38px rgba(0, 0, 0, 0.1);
}

.cdk-overlay-dark-backdrop {
	background-color: unset !important;
}

button {
	&:focus {
		outline: none !important;
	}
}

.mat-button-focus-overlay {
	background-color: transparent !important;
}

.mat-snack-bar-container {
	padding: 0px !important;
	max-width: none !important;
}

.row {
	margin-left: 0;
	margin-right: 0;
}

@media (max-width: 800px) {
	::-webkit-scrollbar {
		width: 5px !important;
		height: 5px;
	}
}
::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
	background: var(--secondary);
	&:hover {
		background: var(--bs-primary);
	}
}

.vidole-curve-content {
	background-color: $primary;
	.curve {
		border-radius: $vidole-curve;
		background-color: #fff;
	}
	.curve-main {
		border-radius: $vidole-curve-main;
		background-color: #fff;
	}
}

.text-white {
	color: $vidole-white;
}

.text-orange {
	color: $primary;
}

.text-grey {
	color: $vidole-text-color;
}

.text-red {
	color: $vidole-red;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type='number'] {
	-moz-appearance: textfield;
}

.vidole-checkbox-container {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	/* Hide the browser's default checkbox */
	input {
		position: absolute;
		opacity: 0;
		height: 0;
		width: 0;
		&:checked {
			.checkmark {
				background-color: $primary;
			}
			~ .checkmark {
				background-color: $primary;
				&:after {
					display: block;
				}
			}
		}
	}

	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		width: 30px;
		height: 30px;
		box-shadow: 0px 2px 3px 0px #908f8f;
		border-radius: 3px;
		&:after {
			content: '';
			position: absolute;
			display: none;
			left: 12px;
			top: 7px;
			width: 7px;
			height: 15px;
			border: solid white;
			border-width: 0 3px 3px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}

		&.check-sm {
			width: 25px;
			height: 25px;
			&:after {
				left: 9px;
				top: 4px;
			}
		}
		&.check-lg {
			width: 30px;
			height: 30px;
			&:after {
				left: 12px;
				top: 7px;
			}
		}

		&.circle {
			border-radius: 50%;
		}
	}
}

a {
	text-decoration: none;
	color: $vidole-text-color;
	&:hover {
		color: $vidole-text-color;
		text-decoration: none !important;
	}
}
.pointer {
	cursor: pointer;
}

/*  =========================== MODAL STYLE =========================== */
@media (max-width: 800px) {
	.dialog-wrapper {
		.close-btn {
			&.type-text {
				.text-wrapper {
					font-size: 20px !important;
				}
			}
		}
	}
}
@media (max-height: 500px) {
	.dialog-wrapper {
		.close-btn {
			&.type-text {
				.text-wrapper {
					font-size: 18px !important;
				}
			}
		}
	}
}

.dialog-wrapper {
	max-height: 70vh;
	max-width: $content-width;
	&.width-80 {
		width: 80vw;
	}
	&.width-lg {
		width: 70vw;
	}
	&.dialog-lg {
		width: 70vw;
		height: 55vh;
	}

	.close-btn {
		position: absolute;
		width: inherit;
		max-width: $content-width;
		&.type-text {
			.text-wrapper {
				font-size: 25px;
				display: flex;
				justify-content: flex-end;
				span {
					position: absolute;
					font-weight: 200;
					transform: translateY(-$default-modal-close-margin);
				}
			}
		}
		.img-wrapper {
			margin-right: -24px;
		}
	}

	.wrapper {
		padding: 25px;
	}
}

.cookie-modal-pannel {
	margin-left: 10px !important;
	margin-right: 10px !important;
	.mat-dialog-container {
		padding: 25px !important;
		border-radius: 15px;
		box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.2);
	}

	&.height-small {
		max-width: 550px !important;
		margin-top: 30vh !important;
	}
}

/* ==================== NG_MAT INPUT STYLE ====================== */
.mat-form-field-infix,
.mat-select-value,
.mat-option-text,
.mat-select-value-text,
.mat-option,
.mat-active {
	color: $primary !important;
	// width: unset !important;
}
.mat-form-field.mat-focused.mat-primary .mat-select-arrow,
.mat-select-arrow {
	width: 0;
	height: 0;
	border-left: 9px solid transparent !important;
	border-right: 9px solid transparent !important;
	border-top: 12px solid !important;
	color: $primary !important;
	margin: 0 4px;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple {
	background-color: #f44336 !important;
}
.mat-form-field.mat-focused .mat-form-field-ripple {
	background-color: $primary;
}
.mat-form-field.mat-focused .mat-form-field-label,
.mat-select-placeholder {
	color: $primary !important;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float
	.mat-form-field-label {
	transform: translateY(-1.28125em) perspective(100px) translateZ(0.001px);
}
.mat-form-field-appearance-legacy .mat-form-field-label {
	font-size: 15px;
	color: $vidole-text-color !important;
	&.mat-form-field-empty {
		span {
			color: $primary;
		}
	}
}

.mat-divider {
	border-top: 2px solid $primary !important;
}
/* ============================================================= */

/* ======================= TEXT SCALE ========================== */

@media screen and (max-width: $screen-md) {
	.title {
		font-size: 23px !important;
	}
	.text-xxl {
		font-size: 25px !important;
	}
}
@media screen and (max-width: $screen-sm) {
	.title {
		font-size: 21px !important;
	}
	.text-xs {
		font-size: 13px;
	}
	.text-xxl {
		font-size: 25px !important;
	}
}

@media screen and (max-width: $screen-xs) {
}
.title {
	font-size: 25px;
}
.text-large {
	font-size: 19px;
}
.text-medium {
	font-size: 16px;
}
.text-small {
	font-size: 14px;
}
.text-xs {
	font-size: 12px;
}
.text-xxl {
	font-size: 40px;
}
/* ============================================================ */

/* NGX_SPINNER */
.overlay {
	background-color: rgba($color: orange, $alpha: 0.5);
	.la-ball-fussion {
		> div {
			background-color: orange;
		}
	}
}

/*-----------------------------------------------------------*/
/* Text color*/
.text-vidole {
	color: $primary;
}

.mat-spinner circle {
	stroke: red;
	color: red;
}
