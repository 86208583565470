@media (max-width: 700px) {
	.cdk-overlay-pane {
		max-width: 100vw !important;
		max-height: 100vh !important;
	}

	.fullscreen-sm {
		.mat-dialog-container.mat-dialog-container {
			border-radius: 0 !important;
		}
	}
}
