@import 'node_modules/@angular/material/theming';
@include mat-core();
@import 'buttons';
@import 'table';
@import 'menu';
@import './bootstrap.scss';
@import 'ng-select.scss';
@import 'mat-dialog.scss';

//the first two are the custom palettes we declared above
$my-theme-primary: mat-palette($mat-orange);
$my-theme-accent: mat-palette($mat-pink);
//the third, for the warn color, is the predefined material red palette
$my-theme-warn: mat-palette($mat-red);

$my-theme: mat-light-theme($my-theme-primary, $my-theme-accent, $my-theme-warn);

@include angular-material-theme($my-theme);

:root {
	--bs-primary-rgb: 240, 188, 104;
	--secondary: #ffcc35;
}

p {
	color: #707070;
}

.mat-dialog-container {
	border-radius: 9px !important;
	box-shadow: none !important;
}
