table.table tr th,
table.table tr td {
	border: none;
	padding: 12px 15px;
	vertical-align: middle;
}
table.table tr th:first-child {
	width: 60px;
}
table.table tr th:last-child {
	width: 80px;
}
table.table-striped tbody tr:nth-of-type(odd) {
	background-color: #fcfcfc;
}
table.table-striped.table-hover tbody tr:hover {
	background: #f5f5f5;
}
table.table-striped.table-rounded tbody tr {
	td:first-child {
		border-top-left-radius: 15px;
		border-bottom-left-radius: 15px;
	}
	td:last-child {
		border-top-right-radius: 15px;
		border-bottom-right-radius: 15px;
	}
}
table.table th i {
	font-size: 13px;
	margin: 0 5px;
	cursor: pointer;
}
table.table td a {
	font-weight: bold;
	color: #566787;
	display: inline-block;
	text-decoration: none;
}
table.table td a:hover {
	color: #2196f3;
}
