$primary: #3aafbc;
$secondary: #fdca00;
$min-contrast-ratio: 1.7;
$color-contrast-dark: #fff;
$progress-border-radius: 1.25rem;

$vidole-white: rgba(255, 255, 255, 1);
$vidole-red: #d33c19;
$vidole-text-color: rgba(112, 112, 112, 1);

$vidole-curve: 100% 0% 100% 0%/70% 100% 0% 0%;
$vidole-curve-main: 100% 0% 100% 0% / 34% 100% 0% 81%;

$content-width: 1140px;
$nav-height: 65px;
$default-modal-close-margin: 50px;
$default-modal-padding: 25px;

$color-cod-gray: #191919;
$color-dusty-gray: #959595;
$color-iron-gray: #E0E2E5;

/* BREAK POINTS */

$screen-xs: 350px;

$screen-sm: 600px; // = Angular flex layout xs

$screen-md: 750px;

$screen-lg: 959px; // = angular flex layout lower bound sm

$screen-max: 1140px; // = max content width

$screen-ip: 375px;

$breakpoints: (
	xs: 350px,
	sm: 600px,
	md: 750px,
	lg: 959px,
	max: 1140px
);
$enable-validation-icons: false;

@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
